#navigation-bar {
    background: black;
    /* height: 39px; */
    display: flex;
    /* align-items: flex-start; */
    justify-content: flex-end;
}

#navigation-bar a{
    color: white;
    text-decoration: none;
    font-size: 32px;
    margin-left: 20px;
    margin-right: 20px;
}