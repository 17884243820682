#about {
    text-align: center;
    margin-left: 25%;
    margin-right: 25%;
}

#about p{
    text-align: justify;
}

#about h1{
    text-align: left;
}

#contact-links img{
    margin: 20px;
}

#contact-links {
    display: flex;
    justify-content: center;
}